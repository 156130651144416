<template>
    <div class="search">
        <div style="padding: 0 36px 20px 36px">
            <div class="top-fix">
                <div class="trades uf uf-pj">
                    <div class="trade-li uf uf-ac" :class="{on: curTradeIndex === -1}"
                         @click="tradesClick('all')">
                        <div class="icon-box uf uf-ac uf-pc">
                            <i class="iconfont icon-all" style="color: #001126"></i>
                        </div>
                        <div class="name fwb f16">全部</div>
                    </div>
                    <div class="trade-li uf uf-ac" v-for="(item, index) in trades" :key="index"
                         :class="{on: index === curTradeIndex}" @click="tradesClick(index)">
                        <div class="icon-box uf uf-ac uf-pc">
                            <i :class="`iconfont ${item.icon}`" :style="{color: item.color}"></i>
                        </div>
                        <div class="name fwb f16">{{ item.name }}</div>
                    </div>
                </div>
            </div>
            <div class="uf uf-ac search-btn">
                <el-input placeholder="请输入搜索内容" class="mr-2" v-model="dataForm.serviceName"
                          @keyup.enter.native="getDataList(true)"></el-input>
                <el-button type="primary" class="button" icon="el-icon-search" @click="getDataList(true)">搜索
                </el-button>
            </div>
            <div class="advice-box left uf uf-ver">
                <div class="title uf uf-ac uf-pc">
                    <i class="iconfont icon-remen mr-2"></i>
                    <span>热门推荐</span>
                </div>
                <div class="con-box uf-f1 scroll">
                    <div class="c-list pr" v-for="item in hotList" :key="item.id" @click="chooseProduct(item)">
                        <div class="jiaobiao pr">
                            <div class="txt">热门</div>
                            <i class="iconfont icon-jiaobiao red"></i>
                        </div>
                        <div class="name f15 fwb">{{ item.serviceName }}</div>
                        <div class="mt-2 f14 op-07">所属行业：{{ item.itemName }}</div>
                        <div class="mt-1 f14"><span class="op-07">服务商：</span><span class="c-base">{{ item.enterpriseName }}</span></div>
                    </div>
                </div>
            </div>
            <div class="advice-box right uf uf-ver">
                <div class="title uf uf-ac uf-pc">
                    <i class="iconfont icon-jinri mr-2"></i>
                    <span>今日推荐</span>
                </div>
                <div class="con-box uf-f1 scroll">
                    <div class="c-list pr" v-for="item in todayList" :key="item.id" @click="chooseProduct(item)">
                        <div class="jiaobiao pr">
                            <div class="txt">今日</div>
                            <i class="iconfont icon-jiaobiao blue"></i>
                        </div>
                        <div class="name f15 fwb">{{ item.serviceName }}</div>
                        <div class="mt-2 f14 op-07">所属行业：{{ item.itemName }}</div>
                        <div class="mt-1 f14"><span class="op-07">服务商：</span><span class="c-base">{{ item.enterpriseName }}</span></div>
                    </div>
                </div>
            </div>
            <div class="list-box tal" v-loading="loading">
                <template v-if="dataList.length > 0">
                    <!--          <service-card class="100%" :service="comItem" @showDetail="chooseProduct(comItem)"/>-->
                    <service-card v-for="item in dataList" class="100%" :service="item"
                                  @showDetail="chooseProduct(item)" :key="item.id"/>
                    <!--          <div class="zhanwei" style="width: 430px"></div>-->
                    <!--          <div class="zhanwei" style="width: 430px"></div>-->
                </template>
                <template v-else>
                    <div class="tac wi100" style="height: 300px">
                        <icon-svg class="mt-3" style="font-size: 200px" name="wushuju"></icon-svg>
                        <div class="f14" style="color: #909399">暂无数据</div>
                    </div>
                </template>
            </div>
        </div>
        <div class="pagination wi100 mt-4 tac">
            <el-pagination
                    background
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageIndex"
                    :page-sizes="[10, 20, 50]"
                    :page-size="pageSize"
                    :total="totalCount"
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import {checkIsNull} from '../../utils'
import routerTransfer from '../../utils/routerTransfer'
import ServiceCard from '../components/service-card'

export default {
    name: 'search',
    components: {
        ServiceCard
    },
    data() {
        return {
            visible: false,
            maxLevel: 1,
            curTradeIndex: -1,
            hotList: [],
            todayList: [],
            trades: [
                {name: '生物医药行业', icon: 'icon-shengwuyiyao', color: '#409EFF', color2: '#9FDCFF'},
                {name: '机械装备行业', icon: 'icon-jixie', color: '#ff6900', color2: '#ffbd73'},
                {name: '汽车行业', icon: 'icon-qiche', color: '#008638', color2: '#9cffd2'},
                {name: '厨电行业', icon: 'icon-youyanji', color: '#9200e5', color2: '#d19fff'},
                {name: '纺织行业', icon: 'icon-duanxiujikouyifu', color: '#c5a500', color2: '#c9c579'},
                {name: '包装行业', icon: 'icon-wuliaobaozhuangdan', color: '#409EFF', color2: '#9FDCFF'},
                {name: '家具行业', icon: 'icon-shafa', color: '#ff6900', color2: '#ffbd73'},
                {name: '生鲜食品行业', icon: 'icon-shuiguo', color: '#008638', color2: '#9cffd2'},
                {name: '电梯行业', icon: 'icon-dianti-xiantiao', color: '#9200e5', color2: '#d19fff'},
                {name: '精密模具行业', icon: 'icon-yewumoban', color: '#409EFF', color2: '#9FDCFF'}
            ],
            expandOption: {},
            serviceItemChoose: {},
            serviceItemList: [],
            itemDataList: {},
            comItem: {
                id: '1',
                serviceName: '微生物生物基因组测序组件',
                // serviceInfo: '基因测序是一种新型基因检测技术，能够从血液或唾液中分析测定基因全序列，预测罹患多种疾病的可能性，个体的行为特征及行为合理。基因测序技术能锁定个人病变基因，提前预防和治疗。',
                itemName: '基因测序是一种新型基因检测技术，能够从血液或唾液中分析测定基因全序列，预测罹患多种疾病的可能性，个体的行为特征及行为合理。基因测序技术能锁定个人病变基因，提前预防和治疗。',
                enterpriseName: '山东启明科技有限公司',
            },
            dataForm: {
                serviceName: '',
                itemCode: '',
                sortType: '',
                serviceMethod: '0',
                orgCode: '',
                priceLow: null,
                priceHigh: null
            },
            loading: false,
            dataList: [],
            pageIndex: 1,
            pageSize: 10,
            totalCount: 0,
            totalPage: 0,
            indexLen: {
                1: {len: 2, title: '行业分类'},
                2: {len: 4, title: '二级目录'},
                3: {len: 6, title: '三级目录'},
                4: {len: 8, title: '四级目录'},
                5: {len: 12, title: '五级目录'}
            },
            serviceMethodDict: [],
            priceEnterVisible: false
        }
    },
    computed: {
        keepAliveArr: {
            get() {
                return this.$store.state.common.keepAliveArray
            },
            set(arr) {
                this.$store.commit('common/updateKeepAliveArray', arr)
            }
        }
    },
    created() {
        this.$getDictList('SERVICE_METHOD', (dict) => {
            this.serviceMethodDict = dict.SERVICE_METHOD
        })
        this.dataForm.itemCode = this.$route.params.itemCode || ''
        this.dataForm.serviceName = this.$route.params.serviceName || ''
        this.getServiceItemMenu()
    },
    mounted() {
        routerTransfer.$on('searchEvent', function (data) {
            this.dataForm.serviceName = data.serviceName
            this.getDataList(true)
        }.bind(this))
        this.getDataList(true)
        this.getApiList()
    },
    beforeRouteLeave(to, from, next) {
        if (to.name === 'service') {
            this.keepAliveArr = ['search']
        }
        next()
    },
    beforeDestroy() {
        routerTransfer.$off('searchEvent')
    },
    methods: {
        // 获取服务目录
        getServiceItemMenu() {
            let menu = localStorage.getItem('serviceItemMenu')
            if (!checkIsNull(menu)) {
                let menuObj = JSON.parse(menu)
                this.serviceItemList = menuObj.menu
                this.maxLevel = menuObj.maxLevel || 1
                this.handleExpandOption()
                this.visible = true
                return
            }
            this.$http({
                url: this.$http.adornUrl(this.$api.PUB.MENU),
                method: 'get'
            }).then(({data}) => {
                if (data && data.code === 0) {
                    this.serviceItemList = data.menu.menu
                    this.maxLevel = data.menu.maxLevel || 1
                    localStorage.setItem('serviceItemMenu', JSON.stringify(data.menu))
                    this.handleExpandOption()
                }
                this.visible = true
            }).catch(() => {
                this.visible = true
            })
        },
        // 处理服务类别条件栏
        handleExpandOption() {
            for (let i = 0; i < this.maxLevel; i++) {
                this.expandOption['expand' + (i + 1)] = false
                this.serviceItemChoose['itemChoose' + (i + 1)] = {itemCode: 'all', itemName: '全部'}
                this.itemDataList['dataList' + (i + 1)] = []
            }
            this.handleItem()
        },

        tradesClick(index) {
            if (index === 'all') {
                this.curTradeIndex = -1
                this.dataForm.itemCode = null
            } else {
                this.curTradeIndex = index
                this.dataForm.itemCode = `0${index + 1}`
            }
            this.getDataList(true)
        },
        handleItem() {
            let itemCode = this.dataForm.itemCode
            // 处理每层选择的itemCode
            for (let i = 1; i <= this.maxLevel; i++) {
                if (!checkIsNull(itemCode) && itemCode.length >= this.indexLen[i].len) {
                    let thisItemCode = itemCode.substring(0, this.indexLen[i].len)
                    if (i === 1) {
                        this.serviceItemChoose['itemChoose' + i] = this.serviceItemList.filter(item => item.itemCode === thisItemCode)[0]
                    } else {
                        this.serviceItemChoose['itemChoose' + i] = this.serviceItemChoose['itemChoose' + (i - 1)].children.filter(item => item.itemCode === thisItemCode)[0]
                    }
                } else {
                    this.serviceItemChoose['itemChoose' + i] = {itemCode: 'all', itemName: '全部'}
                }
            }
            // 处理每层的数据
            for (let i = 1; i <= this.maxLevel; i++) {
                if (i === 1) {
                    this.itemDataList['dataList' + i] = this.$deepClone(this.serviceItemList)
                } else {
                    if (this.serviceItemChoose['itemChoose' + (i - 1)].itemCode !== 'all') {
                        this.itemDataList['dataList' + i] = this.serviceItemChoose['itemChoose' + (i - 1)].children
                    } else {
                        this.itemDataList['dataList' + i] = []
                    }
                }
            }
            this.$forceUpdate()
        },
        // 是否展开所有条件
        switchExpand(index) {
            this.expandOption['expand' + index] = !this.expandOption['expand' + index]
            this.$forceUpdate()
        },
        // 选择服务类别监听
        itemClickListener(index, item) {
            // 选择的全部
            if (index === 1 && item.itemCode === 'all') {
                this.dataForm.itemCode = ''
            } else if (item.itemCode === 'all') {
                this.dataForm.itemCode = this.serviceItemChoose['itemChoose' + (index - 1)].itemCode
            } else {
                if (item.itemCode === '800101') {
                    window.open('http://model.smartquality.cn/')
                    return
                }
                this.dataForm.itemCode = item.itemCode
            }
            this.handleItem()
            this.searchData()
        },
        // 热门推荐和今日推荐列表
        getApiList() {
            this.$http({
                url: this.$http.adornUrl(this.$api.PRODUCT.SERVICE.APIPARAM),
                methods: 'get'
            }).then(({data}) => {
                if (data && data.code === 0) {
                    this.hotList = data.data.searchApiHot
                    this.todayList = data.data.searchApiNow
                }
            })
        },
        // 获取产品列表
        getDataList(refresh) {
            if (refresh) {
                this.pageIndex = 1
            }
            this.loading = true
            // let filter = "itemCode:"+ itemCode + "*,serviceMethod:" + serviceMethod
            this.$http({
                url: this.$http.adornUrl(this.$api.PRODUCT.SERVICE.SOLRLIST),
                methods: 'get',
                params: this.$http.adornParams({
                    // 'page': this.pageIndex,
                    // 'limit': this.pageSize,
                    // 'serviceName': this.dataForm.serviceName || null,
                    // 'itemCode': this.dataForm.itemCode || null ,
                    // 'orgCode': this.dataForm.orgCode || null,
                    // 'serviceMethod': this.dataForm.serviceMethod === 0 ? null : this.dataForm.serviceMethod,
                    // 'sortType': this.dataForm.sortType || null,
                    // 'priceLow': this.dataForm.priceLow || null,
                    // 'priceHigh': this.dataForm.priceHigh || null
                    'startPage': this.pageIndex - 1,
                    'pageSize': this.pageSize,
                    'keywords': this.dataForm.serviceName || null,
                    'serviceLabel': 'API',
                    'itemCode': this.dataForm.itemCode || null,
                    'serviceMethod': this.dataForm.serviceMethod,
                    'sortType': this.dataForm.sortType,
                    'priceLow': this.dataForm.priceLow,
                    'priceHigh': this.dataForm.priceHigh
                })
            }).then(({data}) => {
                if (data && data.code === 0) {
                    // let itemCode = localStorage.setItem('itemCode',this.dataForm.itemCode)
                    // this.dataList = data.page.list
                    // this.totalCount = data.page.totalCount
                    // this.totalPage = data.page.totalPage
                    this.dataList = data.result.list
                    this.totalCount = data.result.total || 0
                    this.totalPage = Math.ceil(data.result.total / this.pageSize) || 1
                } else {
                    this.dataList = []
                    this.totalCount = 0
                    this.totalPage = 1
                }
                this.loading = false
            })
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val
            this.pageIndex = 1
            this.getDataList(true)
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val
            this.getDataList(false)
        },
        chooseMethod(dictId) {
            this.dataForm.serviceMethod = dictId
            this.searchData()
        },
        searchData() {
            this.$nextTick(() => {
                this.getDataList(true)
            })
        },
        // 选择产品
        chooseProduct(item) {
            let itemCodeType = 'search'
            // this.keepAliveArr = ['search']
            this.$router.push({name: 'service', query: {id: item.id, itemCodeType: itemCodeType}})
        },
        //控制字数
        ellipsis(value) {
            if (!value) return ''
            if (value.length > 12) {
                return value.slice(0, 12) + '...'
            }
            return value
        }
    }
}
</script>

<style scoped lang="scss">
.search {
    padding-top: 100px;
    min-height: 83vh;
}

.advice-box {
    position: fixed;
    top: 180px;
    bottom: 60px;
    width: 260px;
    background: #fff;
    border-radius: 10px;
    border: 1px solid #cecece;
    overflow: hidden;
    z-index: 99999;
    .title {
        line-height: 42px;
        background: #deeffd;
        //border-bottom: 1px solid #c2c2c2;
        .icon-remen {
            font-size: 20px;
            color: #b90000;
        }
        .icon-jinri {
            font-size: 20px;
            color: #ff7300;
        }
    }
    .con-box {
        overflow: auto;
        .c-list {
            background: #fff;
            border: 1px solid transparent;
            border-bottom: 1px solid #eeeeee;
            //box-shadow: 0 0 10px rgba(0,0,0,.1);
            padding: 15px 10px;
            //border: 1px solid #bbf7ff;
            cursor: pointer;
            overflow: hidden;
            &:hover {
                border: 1px solid #b6b6b6;
                box-shadow: 0 0 15px rgba(0,0,0,.2);
            }
            .jiaobiao {
                position: absolute;
                right: -3px;
                top: -3px;
                color: #fff;
                .txt {
                    position: absolute;
                    font-size: 12px;
                    right: 3px;
                    top: 6px;
                    transform: rotate(45deg);
                }
                .red {
                    color: #ff6200;
                }
                .blue {
                    color: #00bcff;
                }
                .iconfont {
                    font-size: 46px;
                }
            }
        }
    }
}

.left {
    left: 10px;
}

.right {
    right: 10px;
}

.list-box {
    width: 60vw;
}

.search-btn {
    margin: 20px auto;
    width: 60vw;
    position: sticky;
    top: 147px;
    z-index: 10;
    background: #fff;
}

.pagination {
    position: sticky;
    bottom: 70px;
    z-index: 9;
    width: 60vw;
    margin: 0 auto;
}

.top-fix {
    position: sticky;
    top: 80px;
    z-index: 9;
}

.search-line {
    display: flex;
    width: 100%;
    /*line-height: 2.4rem;*/
    margin-bottom: 16px;
    border-bottom: 1px solid #F2F6FC;
    padding-bottom: 11px;
}

.search-line-label {
    display: flex;
    margin-right: 24px;
    text-align: right;
}

.search-line-label span {
    display: inline-block;
    height: 32px;
    line-height: 32px;
}

.search-line-label span:after {
    content: '：';
}

.search-line .search-line-content {
    flex: 1 1;
    width: 100%;
}

.search-line-content-box {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 32px;
}

.content-box-select {
    padding-right: 50px;
    position: relative;
    max-height: 32px;
    margin-left: -8px;
    overflow: hidden;
    line-height: 32px;
    transition: all .3s;
}

.content-box-select-expand {
    max-height: 200px;
    transition: all .3s;
}

.content-box-select-item {
    box-sizing: border-box;
    display: inline-block;
    margin-right: 24px;
    padding: 0 8px;
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;
}

.content-box-select-item:hover {
    color: #ff9123;
}

.content-box-select .on {
    border-radius: 3px;
    color: #fff;
    background: #ff9123;
}

.search-price > > > .el-input__inner {
    border-radius: 0;
    padding: 0 5px;
}

.search-price > > > .el-button--mini {
    padding: 7px 10px;
}

.infinite-list {
    //background: #fff;
    min-height: 98vh;
    background: url("../../assets/img/img04.jpg");
    background-size: 100% auto;
}

.plan-search {
    position: sticky;
    top: 90px;
    z-index: 99;

    div {
        //box-shadow: 0 5px 40px 8px rgb(0 0 0 / 10%);
        ::v-deep .el-input__inner {
            height: 46px;
            font-size: 16px;
            //width: 80px;
        }

        .btn {
            width: 90px;
            font-size: 16px;
            margin-left: 10px;
        }
    }
}

.item-error {
    width: 245px;
    height: 140px;
    background-image: url("../../assets/img/product/default_3.png");
    background-size: cover;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 2rem;
    padding: 40px 20px;
    color: #FFFFFF;
    overflow: hidden;
}

.pic-box {
    padding: 20px;
    width: 100%;
    height: auto;
    margin-bottom: 15px;
    background-color: #FFFFFF;
    border: 1px solid #e5e5e5;
    border-radius: 16px;
    overflow: hidden;
    /*box-shadow: 0 2px 5px rgb(0 0 0 / 8%);*/
}

.pic-box:hover {
    box-shadow: 0 0px 8px rgba(0, 0, 0, .15);
    transform: translate3d(0, -2px, 0);
    cursor: pointer;
}

.pagination {
    position: sticky;
    bottom: 0px;
    background: #fff;
    padding: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
}

.title-name {
    font-size: 2vw;
    font-weight: bold;
    color: white;
    padding: 10px 30px;
    text-shadow: 0 0 20px rgba(0, 0, 0, .1);
}

.content-box {
    width: 1325px;
    margin: 20px auto;

    .bs {
        border-radius: 0 10px 10px;
    }

    .company-box {
        width: 100%;
        padding: 10px 30px;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 0 20px rgba(0, 0, 0, .2);

        .icon-box {
            width: 50px;
            height: 50px;
            background: linear-gradient(45deg, #0d9aff, #00d2d9);
            border-radius: 30px;
            color: white;

            .iconfont {
                font-size: 25px;
            }
        }
    }
}

.plan-search {
    margin-top: 30px;
}

.trades {
    padding: 10px 15px;
    background: white;
    border-radius: 0 8px 8px;
    box-shadow: 0 0 20px rgba(0, 27, 42, 0.1);

    .trade-li {
        padding: 10px;
        //width: 130px;
        margin: 0 4px;
        cursor: pointer;
        border-radius: 8px;
        border: 1px solid transparent;
        transition: all .3s ease;

        &:hover {
            background: #eeeeee;
        }

        .icon-box {
            .iconfont {
                font-size: 26px;
                margin-right: 13px;
            }
        }
    }

    .on, .on:hover {
        background: #e8e8e8;
        border-color: #23c4ff;
    }
}
</style>
